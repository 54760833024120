<template>
  <div>
      <v-dialog v-model="UserAccessdialog" width="400">
              <v-card v-if="editedUser" flat tile>
                  <v-card-title class="mediumoverline recordtoolbar white--text">
                       <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!editedUser.Profile_Photo">                               
                            {{editedUser.Full_Name.split(' ')[0].substr(0,1)}}{{editedUser.Full_Name.split(' ')[1].substr(0,1)}}
                        </v-avatar>
                        <v-avatar size="40" v-if="editedUser.Profile_Photo">
                            <img :src="editedUser.Profile_Photo" style="object-fit: cover;" >
                        </v-avatar>
                      {{editedUser.Full_Name}} 
                  </v-card-title>
                  <v-card-text>
                      <v-switch label="Can Contribute" v-model="editedUser.Is_Moderator"
                      />
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CloseNonGuestUserDialog()" dark color="warning">
                          Cancel
                      </v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="AddUsertoLibraryModerators(editedUser)" dark color="green">
                          Save</v-btn>
                  </v-card-actions>
              </v-card>
      </v-dialog>
      <v-tabs>
          <v-tab>
              Settings
          </v-tab>
          <v-tab-item>
              <v-list>
                  <v-list-item>
                      <v-text-field label="Icon" :rules="[$store.state.formrules.required]" v-model="ActiveLibrary.Icon" />
                  </v-list-item>
                   <v-card-text>
                      <ContentEditableField style="padding:15px;"
                        :FieldObject="ActiveLibrary" :FieldName="'Description'"
                        :FieldValue="ActiveLibrary.Overview" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                  </v-card-text>
                  <v-list-item>
                      <v-select label="Viewable by" :items="['Public','Members Only']" v-model="ActiveLibrary.PublishType" />
                  </v-list-item>
                  <v-list-item>
                      <v-select label="Who Contributes? (leave blank if Admin Only)" :items="['Specific Members','Non Guest Members','All Site Members']" v-model="ActiveLibrary.EditingRule" />
                  </v-list-item>
              </v-list>
              <v-card-text v-if="ActiveLibrary.EditingRule === 'Specific Members'">
                  <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="OpenNonGuestUserDialog" :AdditionalHeaders="[]" :ShowExpand="false"
                            :UsersLookupArray="NonGuestModerators" :SystemEntities="SystemEntities" :TableName="'Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="false" @AddMethod="''"
                            />
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn dark color="success" @click="UpdateLibraryDetails()"
                  >Save</v-btn>
              </v-card-actions>
          </v-tab-item>
           <v-tab>
               Library
          </v-tab>
          <v-tab-item>
              <DocumentLibraryViewer :EntityCollectionRef="ActiveLibraryConfigRef"
               :TableCollectionRef="ActiveLibraryCollectionRef" :CurrentEntity="ActiveLibrary" :FullGallery="true"
              :System="System" :SystemEntities="SystemEntities"
              />
          </v-tab-item>
      </v-tabs>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DocumentLibraryViewer from '@/components/SuitePlugins/DocumentLibrary/DocumentLibraryViewer';	
import ContentEditableField from '@/components/Database/ContentEditableField'
import UserLookupTable from '@/components/General/UserLookupTable'
export default {
    props: ['System','SystemEntities','ActiveLibrary'],
    components: {DocumentLibraryViewer,ContentEditableField,UserLookupTable},
    data() {
        return {
            UserAccessdialog: false,
            editedUser: '',
            editedUserIndex: -1,
            UserHeaders: [
                {text: 'Full Name', value: 'Full_Name', class: 'overline'},
                {text: 'Business Unit', value: 'Business_Unit.Name', class: 'overline'},
                {text: 'Is Moderator', value: 'Is_Moderator', class: 'overline'},
            ],
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },            
            youtubeurl: value => {
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                return pattern.test(value) || "Not a Youtube Video Link.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },	
    computed:{
        NonGuestModerators(){
            return this.$store.state.UsersArray.filter(user => {
                return !user.IsGuestUser
            }).map(user => {
                let userobj = Object.assign({},user)
                if(this.ActiveLibrary.Moderators && this.ActiveLibrary.Moderators.includes(user.id)){
                    userobj.Is_Moderator = true
                }
                else{
                    userobj.Is_Moderator = false
                }
                return userobj
            })
        },
        ActiveLibraryCollectionRef(){
            return db.collection(this.ActiveLibrary.DisplayName.split(' ').join('').toLowerCase())
        },
        ActiveLibraryConfigRef(){
            return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('DocumentLibraries').doc(this.ActiveLibrary.id)
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        UpdateLibraryDetails(){
            let values = ['Icon','Description','EditingRule','PublishType']
            let libupdateobj = {}
            values.map(prop => {
                if(typeof this.ActiveLibrary[prop] !== 'undefined'){
                    libupdateobj[prop] = this.ActiveLibrary[prop]
                }
            })
            this.ActiveLibraryConfigRef.update(libupdateobj)
        },
        AddUsertoLibraryModerators(user){
            console.log(this.ActiveLibrary,user.Is_Moderator)
            if(!this.ActiveLibrary.Moderators){
                this.ActiveLibrary.Moderators = []
            }
            let moderator = this.ActiveLibrary.Moderators.find(obj => obj === user.id)
            if(moderator && !user.Is_Moderator){
                let index = this.ActiveLibrary.Moderators.indexOf(user.id)
                console.log(index)
                this.ActiveLibrary.splice(index,1)
            }
            else if(!moderator){                
                this.ActiveLibrary.Moderators.push(user.id)
            }
            this.ActiveLibraryConfigRef.update({
                Moderators: this.ActiveLibrary.Moderators
            }).then(updateddoc => {
                this.CloseNonGuestUserDialog()
            })
        },
        OpenNonGuestUserDialog(user){
            this.editedUser = user
            this.editedUserIndex = this.NonGuestModerators.indexOf(user)
            this.UserAccessdialog = true            
        },
        CloseNonGuestUserDialog(){            
            this.UserAccessdialog = false         
            this.editedUser = ''
            this.editedUserIndex = -1   
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        },
    }
}
</script>

<style>

</style>



