<template>
  <div>
     <v-card tile flat>
       <v-card-title  class="BoldBuilderGradient justify-start mediumoverline">
          Document Libraries <v-spacer>
              </v-spacer><v-btn @click="CheckBack()">Back</v-btn>
        </v-card-title>         
      <v-card-text v-if="!ActiveLibrary">
         <v-list>
             <v-list-item v-if="SystemDocumentLibraries.length === 0">
                You do not have Document Libraries at the moment.
             </v-list-item>
             <v-list-item class="caption" v-if="SystemDocumentLibraries.length === 0 && !DocumentLibPluginActive">
                 In order to add a new Library you need to activate the Document Library Plugin
             </v-list-item>
             <v-list-item v-if="SystemDocumentLibraries.length === 0 && DocumentLibPluginActive">
                <v-btn @click="ToggleNewPhotoLibraryDialog('Document Library')">Add Now</v-btn>
             </v-list-item>
              <v-list-item @click="ActivateLibrary(lib)" v-for="lib in SystemDocumentLibraries" :key="lib.itemObjKey">
                  <v-list-item-avatar v-if="!MiniView" :size="MiniView ? 25 : 50" tile :style="MiniView ? '' : 'align-self: center;padding-right: 10px;'">
                    <v-icon :size="40">{{lib.Icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-action>
                    <v-list-item-title style="align-self: start;" class="smallbanneroverline">
                      <v-icon v-if="MiniView" :size="20">{{lib.Icon}}</v-icon>
                      {{lib.DisplayName}}
                    </v-list-item-title>
                    </v-list-item-action>
              </v-list-item>
          </v-list>
      </v-card-text>
      <v-card-text v-if="ActiveLibrary">
          <DocumentLibraryConfig :ActiveLibrary="ActiveLibrary" :SystemEntities="SystemEntities" :System="System"
          />
      </v-card-text>
       </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DocumentLibraryConfig from '@/components/SuitePlugins/DocumentLibrary/DocumentLibraryConfig';	
export default {
    props: ['System','SystemEntities','SystemPhotoLibraries','SystemDocumentLibraries'],
    components: {DocumentLibraryConfig},
    data() {
        return {
            ActiveLibrary: ''
        }
    },	
    computed:{
        DocumentLibPluginActive(){
            return this.PluginDataBase.Document_Library && this.PluginDataBase.Document_Library.Active
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      ToggleNewPhotoLibraryDialog(type){
        this.$store.commit('SetSocialItemInteractMethodProp',type) 
        this.$store.commit('SetSocialItemInteractMethod','ToggleNewDocumentLibraryDialog')
      },
        CheckBack(){
            if(this.ActiveLibrary){
                this.ActiveLibrary = ''
            }
            else{
                this.$emit('GoBack')
            }
        },
      ActivateLibrary(lib){
        this.ActiveLibrary = lib
      }
    }
}
</script>

<style>

</style>



